<template>
  <div>
    <h1>福祉事業部 研修時間登録</h1>
    <p>
      事業所、参加者、開始、終了時間を設定し登録すると勤務実績に研修時間が登録されます。
    </p>
    <v-divider></v-divider>
    <template>
      <div class="mt-5">
        <DatePicker
          v-model="targetdate"
          label="対象年月日"
          prepend-icon="mdi-calendar"
          type="day"
          outlined
        />
        <v-autocomplete
          v-model="selectShozoku"
          :items="shozokus"
          label="事業所"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-autocomplete
          v-model="selectUser"
          :items="users"
          label="参加者"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          multiple
          chips
          return-object
        >
        </v-autocomplete>
        <TimePicker
          v-model="starttime"
          label="開始時間"
          prepend-icon="mdi-clock"
          :rules="[Rules.Required]"
          outlined
        />
        <TimePicker
          v-model="endtime"
          label="終了時間"
          prepend-icon="mdi-clock"
          :rules="[Rules.Required]"
          outlined
        />
      </div>
    </template>
    <div class="d-flex flex-row-reverse">
      <v-btn type="login" color="info" @click="setdata">
        設定
      </v-btn>
    </div>
    <template v-if="isShow()">
      <table>
        <thead>
          <tr>
            <th v-for="(header, i) in setItemHeader" :key="i">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in setitems" :key="index">
            <td>{{ displayDate(item.targetDate) }}</td>
            <td>{{ item.affiliationName }}</td>
            <td>
              {{ item.username }}
            </td>
            <td>
              {{ item.starttime }}
            </td>
            <td>{{ item.endtime }}</td>
            <td>
              <v-icon
                small
                class="ml-1"
                color="error"
                @click="deleteRow(index)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex flex-row-reverse mt-5">
        <v-btn type="login" color="success" @click="regist">
          登録
        </v-btn>
      </div>
    </template>
  </div>
</template>
<script>
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import Forms from "@/mixins/forms";
import WelfareMeeting from "@/models/WelfareMeeting";
import { BUSYO_CODE as BusyoCode } from "@/defines";

const DATE = new Date();
const DATENOW =
  DATE.getFullYear() +
  "-" +
  ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
  "-" +
  ("00" + Number(DATE.getDate())).slice(-2);

export default {
  name: "WelfareTrainingAdd",
  mixins: [Common, ShowDialogs, Forms, Api],
  computed: {},
  data() {
    return {
      targetdate: DATENOW,
      shozokus: [],
      selectShozoku: { code: "", name: "" },
      users: [],
      selectUser: [],
      starttime: "",
      endtime: "",
      setItemHeader: [
        "対象年月日",
        "事業所",
        "社員氏名",
        "開始時間",
        "終了時間",
        "action"
      ],
      setitems: []
    };
  },
  methods: {
    isShow() {
      return this.setitems.length > 0;
    },
    setdata() {
      console.log("setdata");
      if (!this.validate()) return;
      this.selectUser.forEach(user => {
        const f = this.users.filter(e => e.code === user.code);
        const item = {
          targetDate: this.targetdate,
          allifiationId: this.selectShozoku.code,
          affiliationName: this.selectShozoku.name,
          userincode: user.code,
          username: f[0].name,
          starttime: this.starttime,
          endtime: this.endtime
        };
        this.setitems.push(item);
      });
    },
    displayDate(targetDate) {
      const dt = targetDate.split("-");
      return dt[0] + "年" + dt[1] + "月" + dt[2] + "日";
    },
    async regist() {
      console.log("regist");
      this.$loading();
      try {
        const param = [];
        this.setitems.forEach(e => {
          const item = new WelfareMeeting(
            e.targetDate,
            e.allifiationId,
            e.userincode,
            e.starttime,
            e.endtime
          );
          param.push(item);
        });

        await this.$post(this.Paths.trainingAdd, param);
        this.$info("更新しました。");
        this.setitems = [];
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async deleteRow(index) {
      console.log("deleteRow", index);
      if (await this.deleteConfirm()) {
        this.setitems = this.setitems.filter((x, i) => i !== index);
      }
    },
    async deleteConfirm() {
      return await this.$deleteConfirm("削除します。\nよろしいですか？");
    },
    validate() {
      if (this.selectShozoku.code === "") {
        this.$warning("事業所を選択してください");
        return false;
      }
      if (this.selectUser.length == 0) {
        this.$warning("参加者を選択してください");
        return false;
      }
      if (this.starttime === "") {
        this.$warning("開始時間を設定してください");
        return false;
      }
      if (this.endtime === "") {
        this.$warning("終了時間を設定してください");
        return false;
      }
      const dt = new Date();
      const start = new Date(
        dt.getFullYear(),
        dt.getMonth() + 1,
        dt.getDate(),
        this.starttime.split(":")[0],
        this.starttime.split(":")[1],
        0
      );
      const end = new Date(
        dt.getFullYear(),
        dt.getMonth() + 1,
        dt.getDate(),
        this.endtime.split(":")[0],
        this.endtime.split(":")[1],
        0
      );
      if (end <= start) {
        this.$warning("開始時間と終了時間を設定してください");
        return false;
      }
      return true;
    },
    async setPulldown() {
      const param = {
        code1: BusyoCode.WELFARE
      };
      const encode = encodeURI(JSON.stringify(param));
      this.shozokus = await this.$get(this.Paths.shozoku, "query=" + encode);
      this.users = await this.$get(this.Paths.shain1, "query=" + encode);
    }
  },
  async created() {
    console.log("created");
    this.$loading();
    try {
      await this.setPulldown();
    } catch (e) {
      console.log(e);
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

table th {
  text-align: start !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  width: 5%;
  padding: 10px 5px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}

table td {
  text-align: start !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  border-top: none;
  width: 5%;
  padding: 10px 5px;
  font-size: 0.875rem;
}
</style>
