export default class WelfareMeeting {
  targetDate: string;
  affiliationId: string;
  userIncode: string;
  startTime: string;
  endTime: string;
  constructor(
    targetDate = "",
    affiliationId = "",
    userIncode = "",
    startTime = "",
    endTime = ""
  ) {
    this.targetDate = targetDate;
    this.affiliationId = affiliationId;
    this.userIncode = userIncode;
    this.startTime = startTime;
    this.endTime = endTime;
  }
}
